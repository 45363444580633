const handleGA4 = (name) => {
    window.dataLayer.push({
      event: "ga4.trackEvent",
      eventName: "ui_interaction",
      eventParams: {
        section: "mi perfil",
        action: "click",
        element: name,
      },
    });
  };
  
  const handleDataLayer = (categoria, label) => {
    window.dataLayer.push({
      event: "trackEvent",
      eventCategory: categoria,
      eventAction: "click",
      eventLabel: label,
      eventValue: undefined,
      nonInteraction: true,
    });
  };
  
  const handleGA42 = (e) => {
    window.dataLayer.push({
      event: "ga4.trackEvent",
      eventName: "form_interaction",
      eventParams: {
        form_name: "datos personales",
        form_action:
          e.target.value.length > 0 && e.target.value !== "-2"
            ? "completo"
            : "incompleto",
        form_field: e.target.name,
      },
    });
  };
  
  module.exports = { handleDataLayer, handleGA4, handleGA42 };
  
import React, { useState } from 'react';
import { Button,Form,InputGroup,Row,Col } from 'react-bootstrap';
import "./FormAguinaldo.css"
import calcularAguinaldo from "./calcularAguinaldo.js"
import * as yup from "yup";
import Footer from '../Footer/footer.js';
import { handleDataLayer, handleGA4, handleGA42 } from "../../Utils/GA4.js";

const schema = yup.object().shape({
  sueldoNeto: yup.number().required("Este campo es obligatorio"),
  mesesTrabajados: yup.number().required("Este campo es obligatorio"),
});

function FormAguinaldo() {
  const [aguinaldoNeto, setaguinaldoNeto] = useState({"sueldoNeto": 0,"mesesTrabajados":0});
  const [formData, setFormData] = useState(
    {
      sueldoNeto: 0,
      mesesTrabajados: 0,
    });
  const [errors, setErrors] = useState({});

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(formData, { abortEarly: false });
      console.log("El formulario se envio correctamente");
      console.log(formData)
      setaguinaldoNeto(await calcularAguinaldo(formData))
      await delay(700);  
      const ElementGananciasResult = document.getElementById('AguinaldoResultado');
      ElementGananciasResult.scrollIntoView({ behavior: 'smooth' });
      handleDataLayer("formulario", "BTN_calcular");
      // Aquí iría el código para enviar los datos al servidor
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = 'coloque un valor valido';
      });
      setErrors(newErrors);
    }
  };

  const handleChangeNumber = (e) => {
    const soloNumeros = e.target.value.replace(/[^0-9]/g, '');
    setFormData({
      ...formData,
      [e.target.name]: soloNumeros,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleWheel = (e) => {
    e.currentTarget.blur();
  };


//  const handleAddInput = () => {
//    setInputs([...inputs, { value: '' }]);
//  };

  return (
    <div>
    <Form className="FormAguinaldo px-4 py-1" onSubmit={handleSubmit}  >
      <Row className="my-3 " >
          {/* SUELDO BRUTO */}
        <Form.Group md="12" controlId="validation01" className='my-3'>
          <Form.Label className='form_label'>Ingresá tu sueldo NETO (de bolsillo) máximo en este semestre</Form.Label> <span className='leyenda'>*</span>
          <InputGroup>
          <InputGroup.Text className='form_label'>$</InputGroup.Text>
          <Form.Control 
            required
            type="text"
            inputMode="numeric"
            onChange={handleChangeNumber}
            name="sueldoNeto" 
            value={formData.sueldoNeto || ''} 
            pattern="[0-9]*"
            onWheel={handleWheel}
            className='input-no-arrows form_label'
            min={0}
            onBlur={(e) => {
              handleChange(e);
              handleGA42(e);
            }}
            onClick={() =>
              handleDataLayer("principal", "TXT_sueldo_bruto")
            }
          />
          <InputGroup.Text className='form_label'>.00</InputGroup.Text>
          </InputGroup>
          {errors.sueldoNeto && <Form.Text className="text-danger">{errors.sueldoNeto}</Form.Text>}
        </Form.Group>
        
        {/*HIJOS MENORES DE 18 AÑOS */}
        <Form.Group md="12" controlId="validation03" className='my-3'>
          <Form.Label className='me-4 form_label_check form_label'>Cantidad de meses trabajados en este año </Form.Label>
          <div className='mt-2'>
          <Form.Control
            required
            type="text"
            inputMode="numeric"
            onChange={handleChangeNumber}
            name= "mesesTrabajados"
            value={formData.mesesTrabajados }   
            onWheel={handleWheel}
            className= "form_label" 
            min={0}
            onBlur={(e) => {
              handleChange(e);
              handleGA42(e);
            }}
            onClick={() =>
              handleDataLayer("deducciones_personales", "TXT_hijos_menores_de_18_años")
            }
          />
          </div>
        </Form.Group>
        </Row>
        <div id="main_button">
        <Button type="submit" className='form_main_button my-3' 
        >Calcular</Button>
        </div>
    </Form>
    {/* BOX RESULTADOS */}
    <div id='AguinaldoResultado'>
    <div className='AguinaldoResultado my-4' key="aguinaldoNeto" variant="aguinaldoNeto">
      <div className='box_aguinaldoNeto px-3 py-2'>
      <p className='gan_aguinaldoEnMano'>Tu aguinaldo es de:</p>
      <p className='gan_aguinaldoEnMano'>${aguinaldoNeto["aguinaldo"]}</p>
      </div>
      <hr className='gan_linea'></hr>
      <div className='box_sueldoNetoMasInfo px-3 py-2'>
      <div className='my-1'><p className='gan_ImpuestoMensual d-inline '>Meses trabajados: </p> <p className='gan_ImpuestoMensual d-inline ' style={{color : '#38D800'}}>{formData.mesesTrabajados} meses ({aguinaldoNeto["añosTrabajados"]} años)</p> </div>
      </div>
      <Footer />
    </div>
    </div>
      
    </div>
    );
}

export default FormAguinaldo;

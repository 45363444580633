

export async function calcularAguinaldo(formData){
    // declaramos variables 
    let aguinaldo = 0;
    let añosTrabajados = 0;
    let totalAguilnados = 0;
     if (formData.mesesTrabajados < 6){
        aguinaldo = (formData.sueldoNeto / 12) * formData.mesesTrabajados
     }else {
        aguinaldo = (formData.sueldoNeto / 12) * 6
    }

    
    añosTrabajados = Math.floor(formData.mesesTrabajados / 12);
    totalAguilnados =  Math.floor(formData.mesesTrabajados / 6);

    return {"aguinaldo" : aguinaldo,"añosTrabajados":añosTrabajados, "totalAguilnados": totalAguilnados}
  }
  
  export default calcularAguinaldo;